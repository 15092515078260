import React from 'react';

export default function PlaySVG() {
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         height='24'
         viewBox='0 -960 960 960'
         width='24'
         className='play'
      >
         <path d='M320-200v-560l440 280-440 280Zm80-280Zm0 134 210-134-210-134v268Z' />
      </svg>
   );
}
