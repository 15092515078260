import React from 'react';

export default function OrderBy() {
    return (
        <React.Fragment>
            <option value='yeardsc'>Najnoviji</option>
            <option value='yearasc'>Najstariji</option>
            <option value='bestrated'>Najbolje ocijenjeni</option>
            <option value='alphabetical'>Abacedno</option>

            {/* <option value='seeds'>Seeds</option>
            <option value='peers'>Peers</option>
            <option value='rating'>IMDb Ocjeni</option>
            <option value='likes'>YTS Likes</option>
            <option value='rt_audience'>RT Audience</option>
            <option value='downloads'>Downloads</option> */}
        </React.Fragment>
    );
}
