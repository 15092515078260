import React from 'react';

export default function LaguangesList() {
    return (
        <React.Fragment>
            {' '}
            <option
                value=''
                selected='selected'
            >
                Svi
            </option>
            <option value='engleski'>Strani</option>
            <option value='domaci'>Domaći</option>
            {/*  <option value='fr'>
                                                    French (2782)
                                                </option>
                                                <option value='ja'>
                                                    Japanese (2484)
                                                </option>
                                                <option value='es'>
                                                    Spanish (1518)
                                                </option>
                                                <option value='it'>
                                                    Italian (1328)
                                                </option>
                                                <option value='de'>
                                                    German (1085)
                                                </option>
                                                <option value='zh'>
                                                    Chinese (929)
                                                </option>
                                                <option value='ko'>
                                                    Korean (839)
                                                </option>
                                                <option value='cn'>
                                                    Cantonese (754)
                                                </option>
                                                <option value='hi'>
                                                    Hindi (641)
                                                </option>
                                                <option value='sv'>
                                                    Swedish (375)
                                                </option>
                                                <option value='ru'>
                                                    Russian (372)
                                                </option>
                                                <option value='pt'>
                                                    Portuguese (360)
                                                </option>
                                                <option value='ro'>
                                                    Romanian (324)
                                                </option>
                                                <option value='pl'>
                                                    Polish (310)
                                                </option>
                                                <option value='th'>
                                                    Thai (222)
                                                </option>
                                                <option value='nl'>
                                                    Dutch (221)
                                                </option>
                                                <option value='da'>
                                                    Danish (207)
                                                </option>
                                                <option value='tr'>
                                                    Turkish (207)
                                                </option>
                                                <option value='no'>
                                                    Norwegian (139)
                                                </option>
                                                <option value='ta'>
                                                    Tamil (139)
                                                </option>
                                                <option value='ar'>
                                                    Arabic (134)
                                                </option>
                                                <option value='id'>
                                                    Indonesian (133)
                                                </option>
                                                <option value='vi'>
                                                    Vietnamese (121)
                                                </option>
                                                <option value='hu'>
                                                    Hungarian (118)
                                                </option>
                                                <option value='te'>
                                                    Telugu (114)
                                                </option>
                                                <option value='fi'>
                                                    Finnish (111)
                                                </option>
                                                <option value='cs'>
                                                    Czech (100)
                                                </option>
                                                <option value='fa'>
                                                    Persian (81)
                                                </option>
                                                <option value='tl'>
                                                    Tagalog (80)
                                                </option>
                                                <option value='uk'>
                                                    Ukrainian (56)
                                                </option>
                                                <option value='et'>
                                                    Estonian (52)
                                                </option>
                                                <option value='el'>
                                                    Greek (51)
                                                </option>
                                                <option value='he'>
                                                    Hebrew (50)
                                                </option>
                                                <option value='pa'>
                                                    Punjabi (44)
                                                </option>
                                                <option value='ml'>
                                                    Malayalam (40)
                                                </option>
                                                <option value='ms'>
                                                    Malay (31)
                                                </option>
                                                <option value='bn'>
                                                    Bangla (27)
                                                </option>
                                                <option value='ca'>
                                                    Catalan (25)
                                                </option>
                                                <option value='is'>
                                                    Icelandic (20)
                                                </option>
                                                <option value='ur'>
                                                    Urdu (20)
                                                </option>
                                                <option value='sr'>
                                                    Serbian (18)
                                                </option>
                                                <option value='sk'>
                                                    Slovak (17)
                                                </option>
                                                <option value='kn'>
                                                    Kannada (17)
                                                </option>
                                                <option value='lv'>
                                                    Latvian (14)
                                                </option>
                                                <option value='mr'>
                                                    Marathi (14)
                                                </option>
                                                <option value='lt'>
                                                    Lithuanian (10)
                                                </option>
                                                <option value='bg'>
                                                    Bulgarian (10)
                                                </option>
                                                <option value='sh'>
                                                    Serbo-Croatian (9)
                                                </option>
                                                <option value='hr'>
                                                    Croatian (9)
                                                </option>
                                                <option value='xx'>
                                                    xx (8)
                                                </option>
                                                <option value='ka'>
                                                    Georgian (8)
                                                </option>
                                                <option value='eu'>
                                                    Basque (7)
                                                </option>
                                                <option value='mk'>
                                                    Macedonian (7)
                                                </option>
                                                <option value='gl'>
                                                    Galician (7)
                                                </option>
                                                <option value='af'>
                                                    Afrikaans (6)
                                                </option>
                                                <option value='ga'>
                                                    Irish (6)
                                                </option>
                                                <option value='wo'>
                                                    Wolof (6)
                                                </option>
                                                <option value='ku'>
                                                    Kurdish (6)
                                                </option>
                                                <option value='yo'>
                                                    Yoruba (6)
                                                </option>
                                                <option value='am'>
                                                    Amharic (5)
                                                </option>
                                                <option value='bo'>
                                                    Tibetan (5)
                                                </option>
                                                <option value='sq'>
                                                    Albanian (5)
                                                </option>
                                                <option value='sw'>
                                                    Swahili (4)
                                                </option>
                                                <option value='hy'>
                                                    Armenian (4)
                                                </option>
                                                <option value='kk'>
                                                    Kazakh (4)
                                                </option>
                                                <option value='sl'>
                                                    Slovenian (4)
                                                </option>
                                                <option value='yue'>
                                                    Cantonese (4)
                                                </option>
                                                <option value='bs'>
                                                    Bosnian (3)
                                                </option>
                                                <option value='km'>
                                                    Khmer (3)
                                                </option>
                                                <option value='ps'>
                                                    Pashto (3)
                                                </option>
                                                <option value='st'>
                                                    Southern Sotho (3)
                                                </option>
                                                <option value='zu'>
                                                    Zulu (3)
                                                </option>
                                                <option value='cmn'>
                                                    cmn (3)
                                                </option>
                                                <option value='gu'>
                                                    Gujarati (3)
                                                </option>
                                                <option value='la'>
                                                    Latin (2)
                                                </option>
                                                <option value='mn'>
                                                    Mongolian (2)
                                                </option>
                                                <option value='nb'>
                                                    Norwegian Bokmål (2)
                                                </option>
                                                <option value='yi'>
                                                    Yiddish (2)
                                                </option>
                                                <option value='mi'>
                                                    Maori (2)
                                                </option>
                                                <option value='ak'>
                                                    Akan (2)
                                                </option>
                                                <option value='ht'>
                                                    Haitian Creole (2)
                                                </option>
                                                <option value='mt'>
                                                    Maltese (2)
                                                </option>
                                                <option value='xh'>
                                                    Xhosa (2)
                                                </option>
                                                <option value='my'>
                                                    Burmese (2)
                                                </option>
                                                <option value='ne'>
                                                    Nepali (2)
                                                </option>
                                                <option value='lo'>
                                                    Lao (2)
                                                </option>
                                                <option value='bm'>
                                                    Bambara (2)
                                                </option>
                                                <option value='ab'>
                                                    Abkhazian (1)
                                                </option>
                                                <option value='os'>
                                                    Ossetic (1)
                                                </option>
                                                <option value='aa'>
                                                    Afar (1)
                                                </option>
                                                <option value='lb'>
                                                    Luxembourgish (1)
                                                </option>
                                                <option value='ky'>
                                                    Kyrgyz (1)
                                                </option>
                                                <option value='az'>
                                                    Azerbaijani (1)
                                                </option>
                                                <option value='so'>
                                                    Somali (1)
                                                </option>
                                                <option value='iu'>
                                                    Inuktitut (1)
                                                </option>
                                                <option value='lg'>
                                                    Ganda (1)
                                                </option>
                                                <option value='be'>
                                                    Belarusian (1)
                                                </option>
                                                <option value='ig'>
                                                    Igbo (1)
                                                </option>
                                                <option value='cy'>
                                                    Welsh (1)
                                                </option>
                                                <option value='zxx'>
                                                    No linguistic content (1)
                                                </option>
                                                <option value='fil'>
                                                    Filipino (1)
                                                </option>
                                                <option value='tw'>
                                                    Twi (1)
                                                </option>
                                                <option value='rw'>
                                                    Kinyarwanda (1)
                                                </option>
                                                <option value='or'>
                                                    Odia (1)
                                                </option>
                                                <option value='ase'>
                                                    American Sign Language (1)
                                                </option>
                                                <option value='nan'>
                                                    Min Nan Chinese (1)
                                                </option>
                                                <option value='ber'>
                                                    ber (1)
                                                </option>
                                                <option value='qag'>
                                                    qag (1)
                                                </option>
                                                <option value='gsw'>
                                                    Swiss German (1)
                                                </option>
                                                <option value='ln'>
                                                    Lingala (1)
                                                </option>
                                                <option value='iba'>
                                                    Iban (1)
                                                </option>
                                                <option value='cr'>
                                                    Cree (1)
                                                </option>
                                                <option value='jv'>
                                                    Javanese (1)
                                                </option>
                                                <option value='tn'>
                                                    Tswana (1)
                                                </option>
                                                <option value='qab'>
                                                    qab (1)
                                                </option>
                                                <option value='rom'>
                                                    Romany (1)
                                                </option>
                                                <option value='dz'>
                                                    Dzongkha (1)
                                                </option>
                                                <option value='mo'>
                                                    Romanian (1)
                                                </option>
                                                <option value='sa'>
                                                    Sanskrit (1)
                                                </option>
                                                <option value='ukl'>
                                                    ukl (1)
                                                </option>
                                                <option value='ug'>
                                                    Uyghur (1)
                                                </option> */}
        </React.Fragment>
    );
}
