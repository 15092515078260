import React from 'react';

export default function GenreList() {
    return (
        <React.Fragment>
            <option value=''>Svi</option>
            <option value='Akcija'>Akcija</option>
            <option value='Avantura'>Avantura</option>
            <option value='Animirani'>Animirani</option>
            <option value='Biografija'>Biografija</option>
            <option value='Komedija'>Komedija</option>
            <option value='Krimi'>Krimi</option>
            <option value='Dokumentarni'>Dokumentarni</option>
            <option value='Drama'>Drama</option>
            <option value='Familijarni'>Familijarni</option>
            <option value='Fantazija'>Fantazija</option>
            
            <option value='Istorijski'>Istorijski</option>
            <option value='Horor'>Horor</option>

            <option value='Muzički'>Muzički</option>
  

    
            <option value='Romantični'>Romantični</option>
            <option value='Sci-Fi'>Sci-Fi</option>
        

            <option value='Triler'>Triler</option>
            <option value='Ratni'>Ratni</option>
            <option value='Vestern'>Vestern</option>
        </React.Fragment>
    );
}
